.rotary-slider-gauge {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s ease-out;
}

.rotary-slider-gauge circle {
    r: 1;
}
