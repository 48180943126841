.drag-and-drop-container-border-container {
    width: 30%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.drag-and-drop-container-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    position: relative;
    flex-direction: column;
    padding: 0.5rem;
    box-sizing: border-box;
}


.drag-and-drop-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    border: dashed 1px white;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 900;
    box-sizing: border-box;
    padding: 1rem;
    text-align: center;
    cursor: copy;
}

.drag-and-drop-container:hover {
    background-color: var(--black);
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .drag-and-drop-container-border-container {
        width: 30%;
        height: 100%;
        box-sizing: border-box;
        padding: 0.3rem;
    }
}
