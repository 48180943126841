.header-main-container {
    height: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    box-sizing: border-box;
    flex-direction: column;
    color: white;
    font-weight: 200;
}

.header-sony-container {
    background-color: black;
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0rem 8rem;
    box-sizing: border-box;
}

.header-drumgan-container {
    width: 99%;
    height: 70%;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 1rem 0px 0px;
    position: relative;
}

.header-logo-background {
    height: 100%;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--shiny-grey);
    border-radius: 10px;
}

.header-logo-and-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1rem
}

.helper-text-main-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    font-size: 18px;
    gap: 1.4rem;
}

.helper-text-up-container {
    width: 100%;
    height: 50%;
}

.helper-text-down-container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.helper-text-down-box-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.helper-text-keyboard-container {
    width: 100%;
    height: auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width: 950px) {
    .header-logo-background {
        width: 6rem;
    }
    .header-sony-container {
        padding: 0rem 2rem;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .header-main-container {
        height: 16vh;
    }
}
