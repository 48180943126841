.one-d-variation-border-container {
    width: 70%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.one-d-variation-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    position: relative;
    flex-direction: column;
}

.one-d-variation-top-container {
    width: 100%;
    height: 26%;
    display: flex;
}

.one-d-variation-type-container {
    width:auto;
    height: 2.2rem;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: var(--shiny-grey);
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
}

.one-d-variation-bottom-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.one-d-variation-bottom-left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 80%;
    align-items: center;
    justify-content: center;
}

.one-d-variation-bottom-left-container-intensity-tag {
    position: relative;
    top: 2%;
    font-size: 14px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: lighter;
}

.one-d-variation-bottom-right-container {
    display: flex;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.4rem;
}

.one-d-variation-squared-button-border {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(168.21% 215.88% at 96.3% 100%, #1A191E 0%, #353437 100%);
    box-sizing: border-box;
    padding: 0.1rem;
    border-radius: 10px;
    box-shadow: 2px 2px 1px rgb(0 0 0 / 42%);

    cursor: pointer;
    transition: 400ms;

    width: 100%;
    height: 100%;
}

.one-d-variation-squared-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-45deg, #1A191E, #28272c);
    box-sizing: border-box;
    padding: 0.6rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 100ms;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.4rem;
    font-weight: 900;
    font-size: 26px;
}

.one-d-variation-squared-button-border:hover {
    background: linear-gradient(222deg, var(--light-grey), var(--black) 80%);
}