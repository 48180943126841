.horizontal-slider-container-border-container {
    display: flex;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    padding: 0px 0.1rem;
}

.horizontal-slider-container-main-container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--dark-grey);
    border-radius: 10px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.horizontal-slider-container-top-part {
    width: 100%;
    height: 20%;
    display: flex;
}

.horizontal-slider-container-type-container {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    box-sizing: border-box;
    padding: 0.6rem;
    gap: 0.2rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    z-index: 6;
    font-size: 12px;
}

.horizontal-slider-container-type-container:hover {
    font-weight: 700;
}

.horizontal-slider-container-bottom-part {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
}
