.horizontal-slider-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.horizontal-slider-gauge-container {
    display: flex;
    height: 35%;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
}

.horizontal-slider-input-container {
    display: flex;
    width: 100%;
    height: 65%;
    align-items: center;
    justify-content: center;
}

input[type="range"] {
    -webkit-appearance: none;

    display: flex;
    width: 65%;
    overflow: hidden;

    align-items: center;
    justify-content: center;

    background: #1A191E no-repeat center;
}
