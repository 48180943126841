.main-container-main-container {
    height: 80%;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.5rem 22rem;
    flex-direction: column;
    background: url("../../assets/BG_DrumGAN.svg") repeat;
}

@media screen and (max-width: 1800px) {
    .main-container-main-container {
        padding: 0.5rem 12rem;
    }
}

@media screen and (max-width: 1250px) {
    .main-container-main-container {
        padding: 0.5rem 8rem;
    }
}

@media screen and (max-width: 1000px) {
    .main-container-main-container {
        padding: 0.5rem 3rem;
    }
}

@media  screen and (max-width: 1366px) and (orientation: portrait) {
    .main-container-main-container {
        padding: 5%;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .main-container-main-container {
        height: 72vh;
    }
}


.main-container-top-container {
    display: flex;
    width: 100%;
    height: 70%;
}

.main-container-top-left-container {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

}

.main-container-top-left-small-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
}

.main-container-top-right-container {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.main-container-bottom-container {
    display:flex;
    width: 100%;
    height: 30%;
}

.height-50 {
    height: 50%;
}
.height-33 {
    height: 33%;
}