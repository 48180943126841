.mobile-portrait-main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    row-gap: 5px;
}

.mobile-portrait-explore-and-sliders-border {
    display: flex;
    height: 40%;
    justify-content: center;
    align-items: center;
}

.mobile-portrait-sliders-container {
    display: flex;
    height: 100%;
    width: 100%;
    background: var(--black);
    color: white;
    justify-content: center;
    align-items: center;
}

.mobile-portrait-sliders-border {
    display: flex;
    height: 100%;
    width: 55%;
    background: var(--black);
    border-radius: 10px; /* Replace 10px with your desired border radius value */
    justify-content: center;
    align-items: center;
}

.mobile-portrait-generate-variation-workspaces-container {
    display: flex;
    flex-direction: row;
    height: 35%;
    width: 100%;
    background: var(--black);
    color: white;
    justify-content: center;
    gap: 3px;
}

.mobile-portrait-variation-workspaces-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background: var(--black);
    color: white;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.mobile-portrait-variation-workspaces-border {
    display: flex;
    height: 100%;
    width: 100%;
    background: var(--black);
    border-radius: 10px; /* Replace 10px with your desired border radius value */
    justify-content: center;
    align-items: center;
}

.mobile-portrait-variation-container {
    display: flex;
    height: 100%;
    width: 100%;
    background: var(--black);
    color: white;
    justify-content: center;
    align-items: center;
}

.mobile-portrait-variation-border {
    display: flex;
    height: 50%;
    width: 100%;
    background: var(--black);
    justify-content: center;
    align-items: center;
}
.mobile-portrait-workspaces-container {
    display: flex;
    height: 50%;
    width: 100%;
    background: var(--black);
    color: white;
    justify-content: center;
    align-items: center;
}

.mobile-portrait-workspaces-border {
    display: flex;
    height: 50%;
    width: 100%;
    background: var(--black);
    border: 5px solid #808080; /* Replace #808080 with your desired shade of gray */
    border-radius: 10px; /* Replace 10px with your desired border radius value */
    justify-content: center;
    align-items: center;
}

.mobile-portrait-waveform-container {
    display: flex;
    flex-direction: row;
    height: 25%;
    width: 100%;
}
.mobile-portrait-generate-container {
    display: flex;
    flex-direction: row;
    height: 25%;
    width: 100%;
}

.mobile-portrait-left-border {
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
}