.variation-container-border-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.variation-container-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: -2px 4px 2px rgb(0 0 0 / 42%);
    box-sizing: border-box;
    padding: 0.5rem;
    position: relative;
    pointer-events: none;

}

.variation-container-right-side {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0.5rem;
    z-index: 6;
    position: relative;
}

.variation-container-type-container {
    width: 16%;
    height: 2.2rem;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: var(--shiny-grey);
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    text-transform: uppercase;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
}

.variation-container-loading-main-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 16%;
    height:16%;
}

.variation-container-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.variation-container-left-side {
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    gap: 0.5rem;
}

.left-squared-button {
    width: 100%;
    flex: 1;
    border-radius: 10px 0 0 10px;
    background-color: var(--dark-grey);
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
}

@media  screen and (max-width: 1366px) and (orientation: portrait) {
    .variation-container-border-container {
        /* width: 45%; */
        height: 100%;
        padding: 0;
    }
    .variation-container-type-container {
        width: 20%;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .variation-container-border-container {
        width: 100%;
        height: 100%;
        padding: 0 0.1rem;
    }
    .variation-container-type-container {
        width: 50%;
    }
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .variation-container-border-container {
        width: 100%;
        /* height: 100%; */
        padding: 0 0.1rem;
    }
    .variation-container-type-container {
        width: 50%;
    }
}