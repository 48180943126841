.squared-button {
    width: 100%;
    flex: 1;
    background-color: var(--dark-grey);
    box-shadow: 1px 5px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.1rem;
    cursor: pointer;
}

.squared-button:hover {
    background-color: var(--light-grey);
}
