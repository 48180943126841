.rotary-slider-container {
    border-radius: 50%;
    background: radial-gradient(70.06% 79.8% at 24.4% 20.2%, #353437 29.34%, #121212 81.27%) ;
    border: solid #151515 6px;
    transform-origin: center center;
    box-sizing: border-box;
    cursor: auto;
    transition: transform 1s ease-in-out;
    box-shadow: 1px 2px 1px rgb(0 0 0 / 42%);
    position: relative;
}

.rotary-slider-container:before {
    content: "";
    top:-10%;
    left:-10%;
    right:-10%;
    bottom:-10%;
    border: black 2px solid;
    border-radius: 50%;
    position: absolute;
}

.rotary-slider-container:after {
    content: "";
    top:-1%;
    left:-1%;
    right:-1%;
    bottom:-1%;
    border: black 1px solid;
    border-radius: 50%;
    position: absolute;
}

.rotary-slider-knob {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.rotary-slider-hand {
    width: 6px;
    height: 45%;
    /*background: white;*/
    position: absolute;
    bottom: 50%;
    border-radius: 10px;
    left: calc(44%);
    transform-origin: bottom center;
}

.rotary-slider-hand-point {
    position: absolute;
    top: 10%;
    right: 0;
    transform: translate(0%, -50%);
    width: 6px; /* Adjust the size of the dot */
    height: 6px; /* Adjust the size of the dot */
    border: 1px solid black;
    background-color: white;
    border-radius: 50%;
}

.rotary-slider-hand-before {
    color: white;
    position: absolute;
    bottom: 205%;
    left: calc(50% - 6px);
    transform-origin: bottom center;
}

.rotary-slider-doted-gauge {
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%) rotate(135deg);
}