.mobile-landscape-main-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    row-gap: 5px;
}

.mobile-landscape-explore-waveform-and-sliders-container {
    display: flex;
    flex-direction: row;
    height: 65%;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.mobile-landscape-top-left-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 10px;
}

.mobile-landscape-sliders-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    background: var(--black);
    border-radius: 10px; /* Replace 10px with your desired border radius value */
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.mobile-landscape-generate-variation-workspaces-container {
    display: flex;
    flex-direction: row;
    height: 34%;
    width: 100%;
    color: white;
    justify-content: center;
    gap: 3px;
}

.mobile-landscape-three-sliders-geenrate-variation {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.mobile-landscape-generate-container {
    display: flex;
    /* height: 100%; */
    /* width: 50%; */
    border-radius: 10px; /* Replace 10px with your desired border radius value */
    justify-content: center;
    align-items: center;
}

.mobile-landscape-workspace-container {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 10px; /* Replace 10px with your desired border radius value */
    justify-content: center;
    align-items: center;
}

.mobile-landscape-right-side {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    gap: 0.7rem;
}

.mobile-landscape-explore-waveform {
    display: flex;
    flex-direction: row;
    height: 50%;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    align-content: center;
}

.mobile-landscape-waveform-container {
    display: flex;
    height: 100%;
    width: 70%;
    background: var(--black);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    /* flex-direction: row; */
}

.mobile-landscape-explore-container {
    display: flex;
    height: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.mobile-landscape-variation-container {
    display: flex;
    height: 40%;
    width: 100%;
}

.mobile-landscape-right-container {
    width: 50%;
    height: 100%;
}
.mobile-landscape-left-container {
    width: 50%;
    height: 100%;
}

.height-50 {
    height: 50%;
}

.height-30 {
    height: 30%;
}

.height-70 {
    height: 70%;
}