@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300;400;500;600;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');


body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-grey: #28272c;
  --black: #1A191E;
  --light-grey: #3F4043;
  --shiny-grey: #757575;
  --blue: #3B3650;
  --shiny-purple: #7762CB;
  --dark-pink: #D45FB9;
  --orange: #FF9473;
  --light-pink: #FF6D97;
  --light-yellow: #EDD039;
  --dark-yellow: #DB9C3B;
  --navy-blue: #197FD2;
  --lightGreen: #008F74;
}
