.footer-main-container {
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: white;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-container {
    height: 61%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    font-weight: 200;
    font-size: 12px;
}

.sony-footer {
    height: 39%;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 300;
    font-size: 11px;
    box-sizing: border-box;
    padding: 0rem 22rem;
}

a {
    color: white;
    text-decoration: none;
}

@media  screen and (max-width: 950px) {
    .sony-footer {
        padding: 0rem 2rem;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .footer-main-container {
        height: 12vh;
    }
}
