.vertical-slider-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.vertical-slider-gauge-container {
    display: flex;
    width: 35%;
    align-items: center;
    justify-content: center;

}

.vertical-slider-input-container {
    display: flex;
    height: 100%;
    width: 65%;
    align-items: center;
    justify-content: center;
}

input[type="range"] {
    -webkit-appearance: none;

    display: flex;
    width: 65%;
    transform: rotate(90deg);
    overflow: hidden;

    align-items: center;
    justify-content: center;

    background: #1A191E no-repeat center;
}
