.workspaces-border-container {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.workspaces-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    flex-direction: column;
}

.workspaces-top-container {
    width: 100%;
    height: 20%;
    display: flex;
}

.workspaces-bottom-container {
    width: 100%;
    height: 60%;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    gap: 1rem;
}

.workspaces-type-container {
    width: auto;
    height: auto;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: var(--shiny-grey);
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
}

.workspaces-buttons-container {
    width: 100%;
    height: 20%;
    border-radius: 10px;
    box-shadow: 1px -3px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    font-size: 14px;
}

.keyboard-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.download-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media  screen and (max-width: 950px) {
    .workspaces-bottom-container {
        height: 80%;
        /* display: grid;
        grid-template-columns: repeat(4, 2fr); */
        padding: 0.3rem;
        gap: 0.3rem;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .workspaces-bottom-container {
        height: 94%;
        display: grid;
        grid-template-columns: repeat(4, 2fr);
        padding: 0.3rem;
        gap: 0.3rem;
        text-size-adjust: 50%;
    }
    .workspaces-border-container {
        height: 100%;
    }
}

@media screen and (max-width: 1366px) and (orientation: portrait) {
    .download-button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 1%;;
    }
    .workspaces-top-container {
        height: 2.2rem;
        margin-bottom: 2%;
    }
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .workspaces-top-container {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-between;
    }

    .download-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.4rem;
        margin-right: 0.4rem;
    }

    .workspaces-bottom-container {
        width: 100%;
        height: 80%;
        box-sizing: border-box;
        padding: 1rem;
        display: flex;
        gap: 1rem;
    }

    .workspaces-border-container {
        display: flex;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 2px;
        padding-right: 3px;
    }
}
