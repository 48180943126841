.mobile-one-d-variation-border-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 1% 0 1%;
}

.mobile-one-d-variation-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    position: relative;
    flex-direction: row;
}

.mobile-one-d-variation-bottom-right-container {
    display: flex;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.4rem;
    justify-self: end;
    padding-left: 0%;
}

.mobile-one-d-variation-slider {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
}

.mobile-one-d-variation-text {
    height: 20%;
    margin-bottom: 1%;
}
