.App {
  height: 100vh;
  position: relative;
}

.disable-text-selection{
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

@media screen and (max-width: 1366px) and (orientation: landscape)  {
  @supports (-webkit-touch-callout: none) {
    .App {
      height: 92.5vh;
      overflow: hidden;
    }
  }
}
