.workspace-box-main-container {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}

.workspace-box-top-container {
    width: 100%;
    height: 30%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    background-color: var(--dark-grey);
    font-size: 12px;
    position: absolute;
    z-index: 6;
    text-transform: capitalize;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0.5rem;
}

.workspace-box-bottom-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--black);
    border-radius: 10px;
    flex-direction: column;
}

.workspace-box-letter-container {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    position: relative;
}

.workspace-box-logo-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.workspace-box-digit-container {
    font-size: medium;
}

@media  screen and (max-width: 950px){
    .workspace-box-letter-container {
        height: 100%;
    }
    .workspace-box-bottom-container {
        margin-top: 20%;;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .workspace-box-digit-container {
        font-size: xx-small;
    }
}
