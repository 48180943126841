.waveform-container-border-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.waveform-container-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-direction: column;
    background-color: var(--dark-grey);
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    box-sizing: border-box;
    padding: 0.5rem;
}

.waveform-container-waveform-boundaries {
    width: 100%;
    height: 85%;
}

.waveform-container-sample-name-background {
    width: 100%;
    height: 15%;
    background-color: var(--black);
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    padding: 0.5rem;
    position: relative;
}

.waveform-container-sample-name-grey-background {
    width: 50%;
    height: 100%;
    display: flex;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0.5rem;
    font-size: 10px;
    align-items: center;
    text-transform: capitalize;
}

.waveform-container-right-side {
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    gap: 0.5rem;
}

.black-square {
    width: 100%;
    flex: 1
}

.waveform-container-download-button {
    width: 3.6em;
    aspect-ratio: 1 / 1;
    background-color: var(--dark-grey);
    position: absolute;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.waveform-container-download-button:hover {
    background-color: var(--light-grey);
}


@media  screen and (max-width: 1366px) and (orientation: portrait) {
    /* .waveform-container-border-container {
        height: 100%;
    } */

    .waveform-container-sample-name-background {
        padding: 0;
    }
}


@media screen and (max-height: 430px) and (orientation: landscape) {
    .waveform-container-border-container {
        /* height: 100%; */
        width: 100%;
        padding: 0 0.1rem;
    }

    .waveform-container-sample-name-background {
        padding: 3px;
    }
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .waveform-container-border-container {
        /* height: 100%; */
        width: 100%;
        padding: 0 0.1rem;
    }

    .waveform-container-sample-name-background {
        padding: 0;
    }
}